<template>
    <div class="full-height-layout fill">
        <mercur-card class="full-height-layout fill form mx-4 mb-3">
            <h2 class="font-weight-normal">
                <span v-if="isEdit">Edit supplier attribute mapping</span>
                <span v-else>Add supplier attribute mapping</span>
            </h2>
            <div class="row">
                <div class="col-4">
                    <mercur-input v-model="form.supplierAttributeName" required>
                        Supplier attribute name
                    </mercur-input>
                </div>
                <div class="col-4">
                    <mercur-input v-model="form.supplierAttributeOption" required>
                        Supplier attribute option
                    </mercur-input>
                </div>
                <div class="col-4">
                    <mercur-input v-model="form.supplierAttributePostfix">
                        Supplier attribute postfix
                    </mercur-input>
                </div>
                <div class="col-12">
                    <h4 class="mb-0">Select platform attribute and option to be mapped to supplier</h4>
                    <div class="row">
                        <div class="col-6">
                            <grid-header :quickSearch.sync="attributeFilter.search"></grid-header>
                            <data-table
                                class="fill full-height-layout border"
                                :options="attributesOptions"
                                :items="attributesList"
                                :quickSearch="attributeFilter.search"
                                :selected-rows.sync="selectedAttributes"
                                ref="attributesTable"
                            ></data-table>
                        </div>
                        <div class="col-6">
                            <template v-if="optionsUrl">
                                <grid-header :quickSearch.sync="optionsFilter.search"></grid-header>
                                <data-table
                                    class="fill full-height-layout border"
                                    :options="optionsOptions"
                                    :items="optionsList"
                                    :quickSearch="optionsFilter.search"
                                    :selected-rows.sync="selectedOptions"
                                    ref="optionsTable"
                                ></data-table>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <mercur-button class="btn btn-raised btn-yellow text-uppercase" type="submit" :disabled="loading || $v.form.$invalid" @click="submit">Submit</mercur-button>
            </div>
        </mercur-card>
    </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
export default {
    name: 'AddEditAttributeMapping',
    components: { DataTable, GridHeader },
    data () {
        return {
            loading: false,
            isEdit: false,
            selectedAttributes: [],
            attributesList: null,
            attributeFilter: {
                search: '',
            },
            optionsFilter: {
                search: '',
            },
            form: {},
            optionsUrl: null,
            optionsList: null,
            selectedOptions: [],
            attributesOptions: {
                columns: {
                    'Attribute Name': {
                        field: 'attributeName',
                        checkboxSelection: true,
                    },
                },
                rowSelection: 'single',
                pagination: false,
                quickSearchColumns: ['attributeName'],
                menuTabs: [],
            },
            optionsOptions: {
                columns: {
                    'Attribute Option': {
                        field: 'attributeOption',
                        checkboxSelection: true,
                    },
                },
                rowSelection: 'single',
                quickSearchColumns: ['attributeOption'],
                pagination: false,
                menuTabs: [],
            },
        }
    },
    watch: {
        selectedAttributes: {
            handler (value) {
                if (!value || !value.length) {
                    return
                }
                this.$set(this, 'optionsList', null)
                this.$set(this, 'selectedOptions', [])
                this.$set(this, 'optionsUrl', CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.LIST_OPTIONS.replace('{attributeName}', value[0].attributeName))
                this.$api.post(this.optionsUrl, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    this.$set(this, 'optionsList', data.data.items)
                    if (this.isEdit && this.form.attributeOption) {
                        setTimeout(() => {
                            this.$refs.optionsTable.gridApi.forEachNode((node) => {
                                if (node.data.attributeOption === this.form.attributeOption) {
                                    this.$refs.optionsTable.gridApi.selectNode(node)
                                    this.$delete(this.form, 'attributeOption')
                                }
                            })
                        }, 100)
                    }
                })
            },
            immediate: true,
            deep: true,
        },
    },
    validations: {
        form: {
            supplierAttributeName: { required },
            supplierAttributeOption: { required },
        },
    },
    methods: {
        submit () {
            if (!this.selectedAttributes.length) {
                this.$root.$emit('notification:global', {
                    message: 'Select a mercur attribute',
                    type: 'error',
                })
                return
            }

            if (!this.selectedOptions.length) {
                this.$root.$emit('notification:global', {
                    message: 'Select a mercur option',
                    type: 'error',
                })
                return
            }

            const payload = {
                ...this.form,
                attributeId: this.selectedOptions[0].attributeId,
            }

            this.loading = true

            let url = CONFIG.API.ROUTES.SUPPLIERS.ATTRIBUTE_MAPPINGS.CREATE.replace('{supplierId}', this.supplierId)

            if (this.isEdit) {
                url = CONFIG.API.ROUTES.SUPPLIERS.ATTRIBUTE_MAPPINGS.UPDATE.replace('{supplierId}', this.supplierId)
            }

            this.loading = true
            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Supplier attribute mapping was added`,
                })

                this.$router.push({
                    name: 'SupplierAttributeMappings',
                    params: {
                        supplierId: this.supplierId,
                        ...this.params,
                    },
                })
            }).finally(() => {
                this.loading = false
            })
        },
        presetSelectedAttribute () {
            if (!this.isEdit) {
                return
            }

            const parsedForm = JSON.parse(JSON.stringify(this.form))
            this.$refs.attributesTable.gridApi.forEachNode((node) => {
                if (node.data.attributeName === parsedForm.attributeName) {
                    this.$refs.attributesTable.gridApi.selectNode(node)
                }
            })
        },
    },
    created () {
        const promises = []
        if (this.$route.params.supplierAttributeMappingId) {
            this.isEdit = true
            const url = CONFIG.API.ROUTES.SUPPLIERS.ATTRIBUTE_MAPPINGS.GET
                .replace('{supplierId}', this.supplierId)
                .replace('{supplierAttributeMappingId}', this.$route.params.supplierAttributeMappingId)

            promises.push(new Promise((resolve, reject) => {
                this.$api.get(url).then(({ data }) => {
                    this.$set(this, 'form', data.data)
                    resolve(data)
                })
            }))
        }

        promises.push(new Promise((resolve, reject) => {
            this.$api.post(CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.LIST, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.$set(this, 'attributesList', data.data.items)
                resolve(data)
            })
        }))

        Promise.all(promises).then(() => {
            this.presetSelectedAttribute()
        })
    },
}
</script>
